<template>
    <div>
        <header-portrate style="display:none" />

    <div class="m-2" style="border:1px solid #000;background:lightgoldenrodyellow">
        <posHeader />
        <v-row class="m-2 mt-6">
            <v-col cols="12" style="display:flex;justify-content:center;">
                <div class="p-2 text-center" style="border:1px solid #000;width:200px;background:black !important;color:#fff !important">{{ lang.purchase }}</div>
            </v-col>
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;position:relative">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        
                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getInvoice()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <b-button variant="success"  
                    class="btn-sm addemployeecl addButtom" 
                    :style="`width:155px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="_AddPurch()" 
                    v-b-modal.add_addporch
                    id="multiply-button"
                    color="success">
                    <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_purcahse}}</div>
                </b-button>
            </v-col>
            
            <v-col cols="12" md="12" sm="12" class="mxmnheight" style="padding-top:0px;">
                <v-data-table
                    :headers="headers"
                    :items="purchrows"
                    :search="search"
                    :items-per-page="10"
                    class="elevation-1"
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                >
                <template v-slot:item="row">
                <tr>
                    <td class="text-center" style="width:70px">{{ row.item.bill_number }}</td>
                    <td class="text-center" nowrap>{{ row.item.vendor }}</td>
                    <td style="width:100px;" class="text-center">{{ row.item.bill_date }}</td>
                    <td style="width:100px;" class="text-center">{{ row.item.total }}</td>
                    <td style="width:100px;" class="text-center">{{ row.item.vat }}</td>
                    <td style="width:100px;" class="text-center">{{ row.item.ftotal }}</td>
                    <td class="text-center" style="width:70px;background:red;border-bottom:2px solid #FFF !important;">
                    <v-btn style="font-size:0.2em;background:red;width:70px;color:#FFF;height:25px !important;margin-right:5px;" @click="delItem(row.item)">{{lang.delete}}</v-btn>
                    </td>
                    <td class="text-center" style="width:70px;background:blue;border-bottom:2px solid #FFF !important;">
                    <v-btn style="font-size:0.2em;background:blue;width:70px;color:#FFF;height:25px !important;" @click="ViewItem(row.item)" v-b-modal.pviewPurch>
                        {{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin" style="display:none"></i>
                    </v-btn>
                    </td>
                </tr>
                </template>
                <template slot="body.append">
                        <tr>
                            <th class="backEmpty"> </th>
                            <th class="backEmpty"> </th>
                            <th class="backBlack" style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{lang.totals}} </th>
                            <th class="backRed" style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{ptotals}}</th>
                            <th class="backGreen" style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{pvat}}</th>
                            <th class="backBlack" style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{pftotals}}</th>
                            <th class="backEmpty"></th>
                            <th class="backEmpty"></th>
                        </tr>
                </template>
                </v-data-table>
                <v-container style="direction:rtl">
                    <div class="text-center pt-2" style="direction:rtl">
                    <v-pagination
                        v-model="page"
                        value="10"
                        :length="pageCount"
                        style="direction:rtl"
                        color="#000"
                    ></v-pagination>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <ViewPurchase ref="viewMenow" />
        <vue-snotify></vue-snotify>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ViewPurchase from '@/components/pos-ViewPurchase.vue';
import posHeader from '@/components/pos-header.vue'
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { HeaderPortrate,ViewPurchase,posHeader },
    name: 'POS',
    data() {
        return {
            id:0,
            active_tab:2,
            search: '',
            purchrows:[],
            // sdate: '',
            // edate: '',
            vendor_name: '',
            mobile: '',
            invoiceno: '',
            page: 1,
            pageCount:0,
            sd: {
                day: 1,
                month: (new Date()).getMonth(),
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        ptotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].total) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pvat: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].vat) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pftotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].ftotal) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        headers: function() {
            return [
                {
                    text: this.lang.invoiceno,
                    align: 'center',
                    sortable: true,
                    value: 'bill_number',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.vendor,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.inv_date,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    sortable: false,
                    value: 'total',
                    class:"backRed",
                    printRemove: 0
                },
                {
                    text: this.lang.vat,
                    align: 'center',
                    sortable: false,
                    value: 'vat',
                    class:"backGreen",
                    printRemove: 0
                },
                {
                    text: this.lang.ftotal,
                    align: 'center',
                    sortable: false,
                    value: 'ftotal',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.delete,
                    align: 'center',
                    sortable: false,
                    value: 'none',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    class:"backBlack",
                    value: 'none',
                    printRemove: 1
                }
            ]
        },
    },
    methods: {
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        
        preparedata(){ 
            let exdata = {
                list: "purchaseList",
                title: this.lang.purchase,
                data: [],
            }
            for(let i=0;i<this.purchrows.length;i++){
                exdata.data.push(this.purchrows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.sdate;
  
        },
        _AddPurch(){
            
            this.$refs.addPurch.vendorid = '';
            this.$refs.addPurch.bill_number ='';
            this.$refs.addPurch.modal = false;
            this.$refs.addPurch.vendor = '';
            this.$refs.addPurch.mobile = '';
            this.$refs.addPurch.bill_date = '';
            this.$refs.addPurch.vat_number = '';
            this.$refs.addPurch.vendor_address = '';
            this.$refs.addPurch.paytype = 1;
            this.$refs.addPurch.vattype = 2;
            this.$refs.addPurch.full_total =0;
            this.$refs.addPurch.full_vat =0;
            this.$refs.addPurch.full_ftotal =0;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.cashajl = 1;
            this.$refs.addPurch.tbrows =[];
            this.$refs.addPurch.newrow = {
                item_number: '',
                item_name: '',
                unit: this.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            };
            this.$refs.addPurch.enableAdd();
        },
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.purchrows;
            const reportname = 'فواتير المشتريات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    if(myhead[i].text != 'الاجراء')
                        keys.push(myhead[i].value);
                }
            }
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        printMe(item){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        ViewItem(item){
            this.$refs.viewMenow.billid = item.id;
            this.$refs.viewMenow.getBill();
            
            //this.$router.push({path: '/view-purchase/'+item.id})
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getPurchase');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.id);
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[type]',1);
            post.append('data[vendor_name]',this.vendor_name);
            post.append('data[mobile]',this.mobile);
            post.append('data[invoiceno]',this.invoiceno);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.purchrows = res.results.data;
            });
        },
        delItem(item)
        {
            const post = new FormData();
            post.append('type','delPurchase');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getInvoice();
            });
        }
    },
    created() {
        this.getStartDate();
        this.getInvoice();
    },
}
</script>

<style>
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
.addButtom{
  position:absolute;
  text-align:center;
  left:0px;
  top:10px;
}
</style>